import dayjs from 'dayjs'

import { LOCALES_DEFAULT_AIRPORT } from '@layers/web/constants/locale'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const ALL_AIRPORTS_CODE = 'ALL'
export const TEMPERATURE_MAX = 99
export const TEMPERATURE_MIN = 0

export const formatDate = date => dayjs(date).format(DATE_FORMAT)

export const defaultUrlParams = (locale) => ({
  airport: LOCALES_DEFAULT_AIRPORT[locale].airport,
  date: dayjs().add(2, 'day').format(DATE_FORMAT),
  duration: 7,
  categories: 1,
  Adults_0: 2,
  Children_0: 0
})

export function isDateValid (date) {
  if (!date) {
    return false
  }
  if (typeof date === 'string') {
    return dayjs(date).isValid()
  }
  if ('min' in date && 'max' in date) {
    return dayjs(date.min).isValid() && dayjs(date.max).isValid()
  }
  return false
}

/*
export function isTripLengthValid (tripLength) {
  if ('min' in tripLength && 'max' in tripLength) {
    return true
  }
  return null
}

const handleDepartureDate = (state, options, payload) => {
  const _payload = { ...payload }

  const selectedStartDateRange = options?.selectedStartDateRange || state.selectedStartDateRange
  let departureDate = {
    min: dayjs(state.selectedStartDate.selectedDate).subtract(selectedStartDateRange, 'days').format(DATE_FORMAT),
    max: dayjs(state.selectedStartDate.selectedDate).add(selectedStartDateRange, 'days').format(DATE_FORMAT),
  }

  if (dayjs(departureDate.min).isBefore(dayjs())) {
    departureDate.min = dayjs().format(DATE_FORMAT)
  }

  if (!selectedStartDateRange) {
    departureDate = dayjs(state.selectedStartDate.selectedDate).format(DATE_FORMAT)
  }

  _payload.departureDate = departureDate

  return _payload
}
*/
const handleDateRangeDates = (state, options, payload) => {
  const _payload = { ...payload }

  // TODO hack for lastminute, this should be refactored into packagessearch/activateLastMinute (or that should be moved here)
  if (state.lastminute) {
    const startDate = state.selectedDateRangeStartDate || dayjs()
    _payload.departureDate = {
      min: formatDate(startDate),
      max: formatDate(dayjs(startDate).add(10, 'days'))
    }
  } else if (state.selectedDateRangeDuration) {
    // TODO HACK for trip duration (non-flex dates), I think this is probably the right place but cross check during refactor
    _payload.departureDate = formatDate(state.selectedDateRangeStartDate)
  } else {
    if (!state.selectedDateRangeStartDate || !state.selectedDateRangeEndDate) {

      return payload
    }
    _payload.departureDate = formatDate(state.selectedDateRangeStartDate)
    _payload.homeDate = formatDate(state.selectedDateRangeEndDate)

  }
  return _payload
}

const handleTripLength = (state, options, payload) => {
  const _payload = { ...payload }

  if (state.lastminute) {
    // TODO hack for lastminute, this should be refactored into packagessearch/activateLastMinute (or that should be moved here)
    _payload.tripLength = {
      min: 7,
      max: 9,
    }
    return _payload
  }

  if (state.selectedDateRangeDuration) {
    if (state.selectedDateRangeDuration.value === 'custom') {
      // TODO HACK edge case where user is redirected to url with duration=n,m, this is the same as a few lines below so should be unified
      _payload.tripLength = {
        min: state.selectedDateRangeDuration.custom[0],
        max: state.selectedDateRangeDuration.custom[1],
      }
    } else {
      _payload.tripLength = {
        min: state.selectedDateRangeDuration,
        max: state.selectedDateRangeDuration + 2
      }
    }
    return _payload
  }

  throw new Error('getSearchOptions: Duration missing')
}

const handleAdditionalOptions = (state, options, payload, getters) => {
  const _payload = { ...payload }
  if (state.filters.boards) {
    _payload.board = state.filters.boards[5 - state.filterBoard]
  }

  if (options?.supplier) {
    _payload.supplier = options.supplier
  }

  if (options?.hotel_code) {
    _payload.hotel_code = options.hotel_code
  }

  if (state?.filterText && state.filterText.length > 0) {
    _payload.text = state.filterText
  }

  if (state?.filterTemperature && !!getters?.getTemperatureRange && !getters?.temperatureRangeIsDefault) {
    _payload.temperature = getters.getTemperatureRange
  }

  if (options?.airport || state.selectedAirport) {
    _payload.airport = options.airport || state.selectedAirport.airport
  } else {
    throw new Error('getSearchOptions: Airport missing')
  }

  if (options?.charterTags) {
    _payload.charterTags = options.charterTags
  }

  return _payload
}

const handleDestinations = (state, options, payload) => {
  const _payload = { ...payload }
  _payload.destinations = [...(new Set((options?.selectedDestinations || state.selectedDestinations || [])
    .concat(
      (options?.selectedDestinationsL1 || state.selectedDestinationsL1).flatMap(country => state.destinations.filter(d => country === d.country && d.visible))
    )
    .filter(d => !!d)
    .map(d => d?.destination_id)
    .filter(Boolean)))]

  return _payload
}

export const hasQueryAllRequiredSearchParams = (query) => {
  const { localeDateFormats } = useDate()
  const { destinations, countries, categories, date, date_end: dateEnd, duration } = query
  const isValidDate = date => dayjs(date, localeDateFormats.L, true).isValid()
  const requiredParams = ['Adults_0', 'airport', 'date']
  const validParams = requiredParams.every(param => query[param])
  const hasDestination = destinations || countries || categories

  const hasAllParams = validParams &&
    hasDestination &&
    isValidDate(date) &&
    (
      (
        dateEnd &&
        isValidDate(dateEnd)
      ) ||
      duration
    )

  return hasAllParams
}

export const filterRoomPayload = rooms => rooms.map(({ ChildAges, ...room }) => {
  const childAges = ChildAges.filter(age => !isEmpty(age))

  return {
    ...room,
    Children: childAges.length,
    ChildAges: childAges
  }
})

export const buildSearchPayload = (state, options = {}, getters = {}) => {
  let payload = {
    rooms: filterRoomPayload(state.rooms),
    rating: state.filterRating,
    tripadvisor: state.filterTripadvisor,
    tags: state.filterTags,
    charterTags: state.filterCharterTags,
    sort: state.sortBy,
    stopOver: { max: state.filterStopover },
  }
 

  payload = handleDateRangeDates(state, options, payload)


  if (state.lastminute || state.selectedDateRangeDuration) {
    payload = handleTripLength(state, options, payload)
  }

  payload = handleAdditionalOptions(state, options, payload, getters)

  payload = handleDestinations(state, options, payload)

  return payload
}

export const buildOptions = (options, key, value) => {
  if (value !== undefined && value !== null && value !== '') {
    options.push(`${key}=${value}`)
  }
}

export const getDuration = (state, { selectedDuration }) => {
  if (!selectedDuration?.value) {
    return
  }

  const isValidCustom = selectedDuration.custom && selectedDuration.custom.length === 2 && selectedDuration.custom.every(d => !isNaN(parseInt(d)) && parseInt(d) > 0)

  if ((selectedDuration.value === 'custom' || selectedDuration.value?.value === 'custom') && isValidCustom) {
    return selectedDuration.custom
  }

  const durationValue = +selectedDuration.value.value

  if (state.durations.some(d => d.value === durationValue)) {
    return durationValue
  }

  return `${durationValue},${selectedDuration.max}`
}

export const getDestinations = (state, params) => {
  if (params.selectedDestinations.length === state.destinations.length) {
    return 'all'
  } else {
    const countries = []
    params.selectedDestinationsL1.forEach((c) => {
      countries.push(c)
      params.selectedDestinations = params.selectedDestinations.filter(d => d.country !== c)
    })
    return {
      countries: countries.join(','),
      destinations: params.selectedDestinations.map(d => d?.destination_id).filter(Boolean).join(','),
    }
  }
}

export const getCategories = (categories) => {
  return categories.map(({ id }) => id).join(',')
}

export const EMPTY_ROOM = {
  Adults: 0,
  Children: 0,
  ChildAges: []
}
export const prepareRoomsForURL = (params) => {
  const rooms = []

  params.rooms.forEach((r, i) => {
    rooms.push({ key: `Adults_${i}`, value: r.Adults })
    rooms.push({ key: `Children_${i}`, value: r.Children })
    if (r.Children > 0) {
      rooms.push({ key: `ChildAges_${i}`, value: r.ChildAges.filter(childAge => !isEmpty(childAge)).join(',') })
    }
  })
  return rooms
}
export const parseRoomsToURL = params => prepareRoomsForURL(params).map(({ key, value }) => `${key}=${value}`)
export const parseRoomsFromURL = (query) => {
  if (!query.Adults_0) {
    return null
  }
  const rooms = []
  const parseRoomProp = (roomProp, queryValue) => roomProp === 'ChildAges'
    ? queryValue.split(',').filter(Boolean).map(x => parseInt(x) || 0)
    : parseInt(queryValue) || 0

  Object.keys(query)
    .filter(rk => rk.match(/Adults_|Children_|ChildAges_/))
    .map(x => x.match(/(\w+)_(\d+)/))
    .forEach(([queryKey, roomProp, roomIndex]) => {
      rooms[roomIndex] = {
        ...rooms[roomIndex] || EMPTY_ROOM,
        [roomProp]: parseRoomProp(roomProp, query[queryKey])
      }
    })

  return rooms
}
export const evaluateRoomsHaveEmptyAgeValues = rooms => !(rooms || [])
  .every(room => (room?.ChildAges || []).every(age => !Number.isNaN(age) && age !== null))

export const evaluatePeoplePerRoom = (rooms) => {
  const r = (rooms || [])
    .map(x => ({
      ...x,
      ChildAges: (x?.ChildAges || []).filter(childAge => !isEmpty(childAge))
    }))

  const mapChildAges = childAge => isNaN(childAge) ? childAge.ChildAge : childAge
  const infantsPerRoom = childAges => childAges.map(mapChildAges).filter(age => age <= 1).length

  return {
    adults: r.reduce((acc, { Adults }) => acc + Adults, 0) || 0,
    children: r.reduce((acc, { ChildAges }) => acc + ChildAges.length, 0) || 0,
    infants: r.reduce((acc, { ChildAges }) => acc + infantsPerRoom(ChildAges), 0) || 0,
    rooms: r.length
  }
}

// tags with a start- or enddate are campaigns
// eslint-disable-next-line camelcase
export const charterTagIsCampaign = tag => tag?.start_date || tag?.end_date
export const charterTagIsNotCampaign = tag => !!tag && !charterTagIsCampaign(tag)
